import React, { useEffect } from 'react';
import styles from './callNow.module.scss'; // Ensure to have appropriate CSS

const CallNow: React.FC = () => {
  useEffect(() => {
    const callNowTab = document.querySelector(`.${styles.callNowTab}`);
    setTimeout(() => {
      if (callNowTab) {
        callNowTab.classList.add(styles.open);
      }
    }, 100); // Delay to trigger the animation
  }, []);

  // Get current date and time
  const now = new Date();
  const dayOfWeek = now.getDay(); // Sunday - 0, Monday - 1, ..., Saturday - 6
  const hour = now.getHours(); // 0 to 23

  // Check if it is a weekday and between 9 AM to 5 PM
  const shouldShow = dayOfWeek >= 1 && dayOfWeek <= 5 && hour >= 5 && hour < 17;

  if (!shouldShow) {
    return null;
  }

  return (
    <div className={styles.callNowTab}>
      <a className={styles.text} href="tel:915-845-2881">Call Now: (915) 845-2881</a>
    </div>
  );
};

export default CallNow;
