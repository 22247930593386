import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Navbar.module.scss';
import logo from '../../logo.svg';

const Navbar: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const navbar = document.querySelector(`.${styles.navbar}`);
    setTimeout(() => {
      if (navbar) {
        navbar.classList.add(styles.open);
      }
    }, 100);
  }, []);

  const scrollToComponent = (componentId: string) => {
    const element = document.getElementById(componentId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      navigate(`#${componentId}`, { replace: true });
    }
  };

  const onLogoClick = () => scrollToComponent('hero');
  const onAboutClick = () => scrollToComponent('about');
  const onServicesClick = () => scrollToComponent('services');

  return (
    <div className={styles.navbar}>
      <img className={styles.logo} alt="Logo" src={logo} onClick={onLogoClick} />
      <div className={styles.navItems}>
        <div onClick={onAboutClick} className={styles.navItem}>About</div>
        <div onClick={onServicesClick} className={styles.navItem}>Services</div>
        <a href="tel:915-845-2881" className={`${styles.navItem} ${styles.contactWrapper}`}>Call Now</a>
      </div>
    </div>
  );
};

export default Navbar;
