import { FunctionComponent } from 'react';
import styles from './flip-card.module.scss';

interface FlipCardProps {
  frontContent: string;
  backContent: string;
  imageUrl: string;
}

const FlipCard: FunctionComponent<FlipCardProps> = ({ frontContent, backContent, imageUrl }) => {
  return (
    <div className={styles.flipCard}>
      <div className={styles.flipCardInner}>
        <div className={styles.flipCardFront} style={{ backgroundImage: `url(${imageUrl})` }}>
          <div className={styles.content}>{frontContent}</div>
        </div>
        <div className={styles.flipCardBack}>
          <div
            className={styles.content}
            dangerouslySetInnerHTML={{ __html: backContent }}
          />
        </div>
      </div>
    </div>
  );
};

export default FlipCard;
