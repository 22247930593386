import React from "react";
import styles from "./About.module.scss";  // Ensure the path and file name are correct
import elpaso from "./elpaso.png";  // Ensure the image path is correct

const About: React.FC = () => (
  <div className={styles.about} id="about">
    <div className={styles.wrapper}>
    <h1>About Us</h1>
      <p className={styles.paragraph}>
        Welcome to All Air Heating and Air Conditioning, where your comfort is
        our mission. We specialize in providing top-notch heating and cooling
        solutions for your home, including state-of-the-art refrigerated air
        conditioning and heating systems, reliable repair services, and
        innovative indoor air quality enhancements. Our preventive maintenance
        programs are designed to keep your systems running smoothly year-round.
      </p>
      <p className={styles.paragraph}>
        With years of expertise and a team of highly trained professionals, we
        are committed to delivering exceptional service and ensuring your
        complete satisfaction. We pride ourselves on our dedication to quality
        and trustworthiness in every job we undertake. Choose All Air Heating
        and Air Conditioning for all your HVAC needs.
      </p>
      <p className={styles.paragraph}>
        Experience the difference that comes with working with the best in the
        business. Cooling Your World, Heating Your Home - All Air, All Year!
      </p>
    </div>
    <img className={styles.aboutImage} alt="El Paso cityscape showing the relevance to local climate" src={elpaso} />
  </div>
);

export default About;
