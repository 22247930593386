import React from 'react';
import Navbar from './components/Navbar/Navbar';
import Hero from './components/Hero/Hero';
import About from './components/About/About';
import Services from './components/Services/Services';
import CallNow from './components/callNow';
import { Helmet } from 'react-helmet';
import './App.scss'; // Global styles if necessary

const App: React.FC = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>All Air - HVAC Services in El Paso, Texas</title>
        <meta name="description" content="All Air Heating and Air Conditioning - Expert HVAC services in El Paso, Texas. Installation, repair, and maintenance of air conditioning, heating, and indoor air quality systems." />
        <meta name="keywords" content="HVAC, El Paso, Texas, air conditioning, heating, indoor air quality, repair, installation, maintenance" />
        <meta name="author" content="All Air Heating and Air Conditioning" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content="All Air Heating and Air Conditioning" />
        <meta property="og:description" content="Professional HVAC services in El Paso, Texas. We specialize in the installation, repair, and maintenance of air conditioning, heating, and indoor air quality systems." />
        <meta property="og:image" content="%PUBLIC_URL%/og-image.png" />
        <meta property="og:url" content="https://www.allairelpaso.com" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="All Air Heating and Air Conditioning" />
        <meta name="twitter:description" content="Professional HVAC services in El Paso, Texas. We specialize in the installation, repair, and maintenance of air conditioning, heating, and indoor air quality systems." />
        <meta name="twitter:image" content="%PUBLIC_URL%/twitter-image.png" />
      </Helmet>
      <div className="appWrapper">
        <Navbar />
        <Hero />
        <About />
        <Services />
      </div>
      <div className='buttonWrapper'>
        <CallNow />
      </div>
    </div>
  );
}

export default App;
