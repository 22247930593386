import { FunctionComponent } from "react";
import styles from "./Services.module.scss";
import FlipCard from "./flip-card"; // Ensure the path is correct

import commercialImage from "./Commercial.png";
import acImage from "./AC.png";
import heatingImage from "./Heating.png";
import airQualityImage from "./AirQuality.png";
import preventativeImage from "./Preventative.png";

const Services: FunctionComponent = () => {
  return (
    <div className={styles.services} id="services">
      <div className={styles.servicesWrapper}>
        <div className={styles.services1}>Services</div>
      </div>
      <div className={styles.commercialHvacParent}>
        <FlipCard
          frontContent="Air Condition"
          backContent={`Installation: High-efficiency AC units.<br />
                        Repair: Prompt and reliable services.<br />
                        Maintenance: Regular upkeep for optimal performance.<br />
                        Replacement: Energy-efficient model upgrades`}
          imageUrl={acImage}
        />
        <FlipCard
          frontContent="Heating"
          backContent={`Installation: Furnaces, heat pumps, and boilers.<br />
                        Repair: Fast, effective repairs.<br />
                        Maintenance: Preventive care to avoid breakdowns.<br />
                        Replacement: Modern, efficient heating systems.`}
          imageUrl={heatingImage}
        />
        <FlipCard
          frontContent="Commercial HVAC"
          backContent={`Installation: Efficient solutions for businesses.<br />
                        Repair and Maintenance: Tailored commercial services.<br />
                        Energy Management: Optimize usage and reduce costs.`}
          imageUrl={commercialImage}
        />
        <FlipCard
          frontContent="Indoor Air Quality"
          backContent={`Air Purification: Remove contaminants.<br />
                        Humidity Control: Humidifiers and dehumidifiers.<br />
                        Duct Cleaning: Eliminate dust and allergens.<br />
                        Ventilation: Ensure proper air circulation.`}
          imageUrl={airQualityImage}
        />
        <FlipCard
          frontContent="Preventative Maintenance"
          backContent={`Seasonal Tune-Ups: Prepare for season changes.<br />
                        Service Agreements: Regular inspections and priority service.`}
          imageUrl={preventativeImage}
        />
      </div>
    </div>
  );
};

export default Services;
