import React, { useState, useEffect } from 'react';
import styles from './Hero.module.scss'; // Ensure the path is correct
import left from './Vector 1.png'; // Ensure the path and name are correct
import right from './Vector 2.png'; // Ensure the path and name are correct

const images: string[] = [
  require('./Hero-1.png'), // Ensure the path and name are correct
  require('./Hero-2.png'), // Ensure the path and name are correct
  require('./Hero-3.png'), // Ensure the path and name are correct
  require('./Hero-4.png'), // Ensure the path and name are correct
  require('./Hero-5.png')  // Ensure the path and name are correct
];

const Hero: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState<string>('');

  useEffect(() => {
    if (direction) {
      const timer = setTimeout(() => setDirection(''), 1000);
      return () => clearTimeout(timer);
    }
  }, [currentIndex, direction]);

  const nextImage = () => {
    setDirection('right');
    setCurrentIndex((currentIndex + 1) % images.length);
  };

  const prevImage = () => {
    setDirection('left');
    setCurrentIndex((currentIndex - 1 + images.length) % images.length);
  };

  return (
    <div className={styles.heroWrapper}>
      <div
        key={`${currentIndex}-${direction}`}
        className={`${styles.component} ${styles[direction]}`}
        id='hero'
        style={{ backgroundImage: `url(${images[currentIndex]})` }}
      ></div>
      <div className={styles.vectorParent}>
        <button onClick={prevImage} className={styles.leftButton}>
          <img alt="Left" src={left} className={styles.vectorImage} />
        </button>
        <button onClick={nextImage} className={styles.rightButton}>
          <img alt="Right" src={right} className={styles.vectorImage} />
        </button>
      </div>
    </div>
  );
};

export default Hero;
